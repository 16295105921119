import { render, staticRenderFns } from "./tableInmueble.vue?vue&type=template&id=2af320a2&scoped=true&"
import script from "./tableInmueble.vue?vue&type=script&lang=js&"
export * from "./tableInmueble.vue?vue&type=script&lang=js&"
import style0 from "./tableInmueble.vue?vue&type=style&index=0&id=2af320a2&prod&lang=css&scoped=true&"
import style1 from "./tableInmueble.vue?vue&type=style&index=1&id=2af320a2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2af320a2",
  null
  
)

export default component.exports